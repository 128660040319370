import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/image/Logomain.png";
import gmail from "../../assets/image/ngm.png";
import fb from "../../assets/image/nfb.png";
import insta from "../../assets/image/nins.png";
import yout from "../../assets/image/yout.png";

function Footer({currencySymbol,dynamicUrl, activePlanIndex, scrollToBundles, bundleSectionRef }) {
  // const navigate = useNavigate();
  // const [email, setEmail] = useState("");

  const [showButtons, setShowButtons] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const bundleSection = bundleSectionRef.current;
    if (bundleSection) {
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowButtons(false);
          } else {
            setShowButtons(true);
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection);
      observer.observe(bundleSection);
      return () => {
        observer.unobserve(bundleSection);
      };
    }
  }, [bundleSectionRef]);

  const handleViewBundlesClickdonate = () => {
    // if (activePlanIndex !== null) {
    //   navigate("/checkout");
    // } else {
    scrollToBundles();
    // }
  };
  const handleViewBundlesClick = () => {
    scrollToBundles();
  };

  return (
    <div className="py-[20px]">
      <div className="2xl:w-[100%]  xl:w-[100%] lg:w-[90%] md:w-[90%] sm:w-[90%] w-[90%]  2xl:max-w-[1140px] xl:max-w-[1040px] lg:max-w-[1040px] md:max-w-[1040px] sm:max-w-[1040px] max-w-[1040px] mx-auto 2xl:block xl:block lg:block hidden hidden hidden">
        <div className="!flex justify-between pb-[8rem]">
          <div className="w-[18%] space-y-[20px]">
            <img className="w-[82px] h-[82px]" src={Logo} alt="" />
            <form
              action="https://petersage.com/facebook-ps"
              target="_blank"
            >
              <div className="flex  items-center w-[100%] ">
                <h4 className="text-[16px] font-[600] ">
                  Join our Exclusive <br /> Facebook Donor Group
                </h4>
              </div>
              <button
                type="submit"
                className="mt-4 hover:opacity-[0.8] text-[14px] bg-[#1A2445] text-white rounded-[3px] font-[600] h-[32px] w-[114px]"
              >
                Join Now
              </button>
            </form>
          </div>
          <div className="w-[14%] pt-[0.8rem]">
            <h3 className="text-[16px]  text-[#455174] font-[600]">About Us</h3>
            <a
              href="/"
              className="text-[14px] inline-block pt-4 font-[400] text-[#455174]"
            >
              Our Team
            </a> <br />
            <a
              href="/"
              className="text-[14px] inline-block pt-4 font-[400] text-[#455174]"
            >
              Our Campaign
            </a>{" "}
            <br />
            <Link to={currencySymbol === "$" ? "/#bundles" : "/uk/#bundles"}>
            <p
                className="text-[14px] inline-block pt-4 font-[400] text-[#455174]"
              >
                  Donors
              </p>
              </Link> <br />
              <Link 
              to={currencySymbol === "$" ? "/partner" : "/uk/partner"}
              >
            <p
                className="text-[14px] inline-block pt-4 font-[400] text-[#455174]"
              >
                 Partner With Team Cheerfulness
              </p>
              </Link>
          </div>
          <div className="w-[14%] pt-[0.8rem]">
            <h3 className="text-[16px]  text-[#455174] font-[600]">Services</h3>

            <a
              href="/"
              className="text-[14px] inline-block pt-4 font-[400] text-[#455174]"
            >
              Terms and Conditions
            </a>
            <a
              href="/"
              className="text-[14px] inline-block pt-4 font-[400] text-[#455174]"
            >
              Our Activities
            </a> <br />
            <a
              href="/"
              className="text-[14px] inline-block pt-4 font-[400] text-[#455174]"
            >
              Copyrights
            </a> <br />
            <Link to={currencySymbol === "$" ? "/fiscalpolicy" : "/uk/fiscalpolicy"}>
            <p
                className="text-[14px] inline-block pt-4 font-[400] text-[#455174]"
              >
                Fiscal policy
              </p>
              </Link>
          
          </div>

          <div className="w-[17%] space-y-4 pt-[0.8rem]">
            <h4 className="text-[16px] text-[#455174] font-[600]">Address</h4>
            <p className="text-[14px] text-[#455174] leading-[25px] font-[400]">
              MOC Consulting & Training, S.L.U.
              <br />
              CIF: B-44674943 <br />
              Avda De Los Pueblos 20 Puerta 72 <br />
              38660 Adeje <br />
              S.C. Tenerife
            </p>
          </div>
          <div className="2xl:w-[27%] xl:w-[27%] lg:w-[27%] md:w-[36%] sm:w-[20%] w-[20%] space-y-4 pt-[0.8rem]">
            <h4 className="text-[14px] text-[#455174] font-[600]">
              Contact us
            </h4>
            <div className="flex space-x-3 items-center">
              <img className="h-[14px]" src={gmail} alt="" />
              <p className="text-[14px] text-[#455174]  font-[400]">
                campaign@teamcheerfulness.com
              </p>
            </div>
            <div className="flex space-x-3">
              <a
                href="https://www.youtube.com/user/PeterSage007/featured"
                target="_blanck"
              >
                <img className="h-[32px]" src={yout} alt="" />
              </a>
              <a
                href="https://petersage.com/facebook-ps"
                target="_blanck"
              >
                <img className="h-[32px]" src={fb} alt="" />
              </a>
              <a
                href="https://www.instagram.com/therealpetersage/?el=footer-website"
                target="_blanck"
              >
                <img className="h-[32px]" src={insta} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className=" border-t border-gray-400  py-4">
          <div className="">
            <div className=" px-[2rem]">
              <div className="w-[100%]">
                <h3 className="text-[14px] text-center font-[400]">
                  © Copyright 2024, All Rights Reserved{" "}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[90%] relative mx-auto max-w-[1240px] 2xl:hidden xl:hidden lg:hidden block block block">
        <div className="block justify-between py-6">
          <div className="w-[100%] space-y-[15px] ">
            <img className="w-[43px] h-[43px]" src={Logo} alt="" />
            <form
              action="https://petersage.com/facebook-ps"
              target="_blank"
            >
              <div className="flex items-center w-[100%] ">
                <h4 className="text-[14px] font-[600] text-[#455174]">
                  Join our Exclusive <br /> Facebook Donor Group
                </h4>
              </div>
              <button
                type="submit"
                className="mt-3 hover:opacity-[0.6] text-[10px] bg-[#1A2445] text-white rounded-[3px] font-[600] py-[8px] px-[28px]"
              >
                Join Now
              </button>
            </form>
          </div>
          <div className="flex justify-between pt-[1rem]">
            <div className="w-[50%]">
              <h3 className="text-[14px]  text-[#455174] font-[600]">
                About Us
              </h3>
              <a
                href="/"
                className="block text-[12px] leading-[23.28px]2 font-[400] text-[#455174]"
              >
                Our Team
              </a>
              <a
                href="/"
                className="block text-[12px] leading-[23.28px] font-[400] text-[#455174]"
              >
                Our Campaign
              </a>
              <Link to={currencySymbol === "$" ? "/#bundles" : "/uk/#bundles"}>
            <p
                className="block text-[12px] leading-[23.28px] font-[400] text-[#455174]"
              >
                  Donors
              </p>
              </Link>
              <Link to={currencySymbol === "$" ? "/partner" : "/uk/partner"}>
            <p
                className="block text-[12px] leading-[23.28px] font-[400] text-[#455174]"
              >
                 Partner With Team Cheerfulness
              </p>
              </Link>
            </div>
            <div className="w-[50%]">
              <h3 className="text-[14px]  text-[#455174] font-[600]">
                Services
              </h3>
              <a
                href="/"
                className="block text-[12px] leading-[23.28px] font-[400] text-[#455174]"
              >
                Privacy Policy
              </a>
              <a
                href="/"
                className="block text-[12px] leading-[23.28px] font-[400] text-[#455174]"
              >
                Terms and Conditions
              </a>
              <a
                href="/"
                className="block text-[12px] leading-[23.28px] font-[400] text-[#455174]"
              >
                Our Activities
              </a>
              <a
                href="/"
                className="block text-[12px] leading-[23.28px] font-[400] text-[#455174]"
              >
                Copyrights
              </a>
              <Link to={currencySymbol === "$" ? "/fiscalpolicy" : "/uk/fiscalpolicy"}>
            <p
               className="block text-[12px] leading-[23.28px] font-[400] text-[#455174]"
              >
                Fiscal policy
              </p>
              </Link>
            </div>
          </div>

          <div className="w-[100%] space-y-4 pt-[1rem]">
            <h4 className="text-[14px] text-[#455174] font-[600]">Address</h4>
            <p className="text-[12px] text-[#455174] font-[400]">
              MOC Consulting & Training, S.L.U.
              <br />
              CIF: B-44674943 <br />
              Avda De Los Pueblos 20 Puerta 72 <br />
              38660 Adeje <br />
              S.C. Tenerife
            </p>
          </div>
          <div className="w-[100%] space-y-4 pt-[1rem]">
            <h4 className="text-[14px] text-[#455174] font-[600]">
              Contact us
            </h4>
            <div className="flex space-x-3 items-center">
              <img className="h-[14px]" src={gmail} alt="" />
              <p className="text-[12px] text-[#455174]">
                campaign@teamcheerfulness.com
              </p>
            </div>
            <div className="flex space-x-3 justify-start pt-3">
              <a href="https://www.youtube.com/user/PeterSage007/featured">
                <img className="h-[33px]" src={yout} alt="" />
              </a>
              <a href="https://petersage.com/facebook-ps">
                <img className="h-[33px]" src={fb} alt="" />
              </a>
              <a href="https://www.instagram.com/therealpetersage/?el=footer-website">
                <img className="h-[33px]" src={insta} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[90%] pb-[4.4rem] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block mx-auto border-t border-gray-400  py-4 mt-5">
        <div className="w-[100%] space-y-3 ">
          <div>
            <h3 className="text-[12px] font-[400] text-center">
              © Copyright 2024, All Rights Reserved
            </h3>
          </div>
        </div>
      </div>
      <div className="cookiebg bottom-[0] z-[44] fixed w-[100%] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block">
        <div className="w-[90%] mx-auto">
          {showButtons && (
            <div className="flex justify-between py-[20px]">
              <button
                className="rounded-full 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[16px] text-[10px] font-[600] text-[#fff] 2xl:px-[42px] xl:px-[42px] lg:px-[42px] md:px-[42px] sm:px-[20px] px-[20px] 2xl:py-[16px] xl:py-[16px] lg:py-[16px] md:py-[16px] sm:py-[13px] py-[10px] bg-[#1E5BB3]"
                onClick={handleViewBundlesClick}
              >
                View Bundles
              </button>
              <button
                className="rounded-full bgbtn 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[16px] text-[10px] font-[600] 2xl:px-[42px] xl:px-[42px] lg:px-[42px] md:px-[42px] sm:px-[20px] px-[20px] 2xl:py-[16px] xl:py-[16px] lg:py-[16px] md:py-[16px] sm:py-[13px] py-[10px]"
                onClick={handleViewBundlesClickdonate}
              >
                Donate
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Footer;
