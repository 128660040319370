import React from "react";
import Logo from "../../assets/image/Logomain.png";
import { Link, useLocation } from "react-router-dom";

function Navbar({ dynamicUrl, currencySymbol }) {
  const location = useLocation();
  const path = location.pathname;

  const isCorporatePage = path === "/partner" || path === "/uk/partner";
  const ukPath = path === "/uk"

  return (
    <div className="2xl:w-[100%]  xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] w-[100%]  2xl:max-w-[1140px] xl:max-w-[1040px] lg:max-w-[1040px] md:max-w-[1040px] sm:max-w-[1040px] max-w-[1040px] mx-auto">
       {currencySymbol === "£" ? (
                 <div className="py-[10px] 2xl:h-[120px] xl:h-[120px] lg:h-[120px] md:h-[120px] sm:h-[70px] h-[65px]  flex justify-between items-center">
                 <div>
                   <Link to={dynamicUrl} className="flex items-center space-x-2">
                     <img
                       className="2xl:h-[80px] 2xl:w-[80px] xl:h-[80px] xl:w-[80px] lg:h-[82px] lg:w-[82px] md:h-[82px] md:w-[82px] sm:h-[43px] sm:w-[43px] h-[43px] w-[43px] cursor-pointer"
                       src={Logo}
                       alt=""
                     />
                     <p className="text-[#23315E] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] font-[700]">
                       Team Cheerfulness
                     </p>
                   </Link>
                 </div>
                 {isCorporatePage ? (
                   <div className="space-x-[35px] w-[44%] 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden">
                     <Link to="#rewards">
                       <p className="text-[#23315E] text-[12px] font-[600]">REWARDS</p>
                     </Link>
                     <Link to="#media">
                       <p className="text-[#23315E] text-[12px] font-[600]">MEDIA</p>
                     </Link>
                     <Link to="#sponsor">
                       <p className="text-[#23315E] text-[12px] font-[600]">TIERS</p>
                     </Link>
                     <Link to="#branding">
                       <p className="text-[#23315E] text-[12px] font-[600]">BRANDING</p>
                     </Link>
                     <Link to="#charity">
                       <p className="text-[#23315E] text-[12px] uppercase font-[600]">
                         Charities
                       </p>
                     </Link>
                   </div>
                 ) : (
                   <div className="space-x-[35px]  w-[44%] 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden">
                     <Link to="#challenge">
                       <p className="text-[#23315E] text-[12px] font-[600]">CHALLENGE</p>
                     </Link>
                     <Link to="#team">
                       <p className="text-[#23315E] text-[12px] font-[600]">TEAM</p>
                     </Link>
                     <Link to="#media">
                       <p className="text-[#23315E] text-[12px] uppercase font-[600]">
                         Influencer Partners
                       </p>
                     </Link>
                     <Link to="#charity">
                       <p className="text-[#23315E] text-[12px] uppercase font-[600]">
                         Charities
                       </p>
                     </Link>
                   </div>
                 )}
                 {isCorporatePage ? (
                   <div className="space-y-[10px] flex flex-col justify-end">
                     <div className="flex justify-center items-center">
                       <a href="#sponsor" className="">
                         <button className="bgbtn 2xl:px-[42px] 2xl:py-[15px] xl:px-[42px] xl:py-[15px] lg:px-[42px] lg:py-[15px] md:px-[42px] md:py-[15px] sm:px-[30px] sm:py-[12px] px-[30px] py-[9px]  2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[16px] text-[10px] font-[600]">
                           Get In Touch
                         </button>
                       </a>
                     </div>
                   </div>
                 ) : (
                   <div className="space-y-[10px] flex flex-col justify-end">
                     <div className="flex justify-center items-center">
                     <Link to="/uk/checkout">
                             <button className="bgbtn 2xl:px-[36px] 2xl:py-[10px] xl:px-[36px] xl:py-[10px] lg:px-[36px] lg:py-[10px] md:px-[36px] md:py-[10px] sm:px-[30px] sm:py-[12px] px-[30px] py-[9px]  2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[10px] font-[600]">
                               Donate
                             </button>
                           </Link>
                     </div>
                   </div>
                 )}
               </div>
              ) : (
                <div className="py-[10px] 2xl:h-[120px] xl:h-[120px] lg:h-[120px] md:h-[120px] sm:h-[70px] h-[65px]  flex justify-between items-center">
        <div>
          <Link to={dynamicUrl} className="flex items-center space-x-2">
            <img
              className="2xl:h-[80px] 2xl:w-[80px] xl:h-[80px] xl:w-[80px] lg:h-[82px] lg:w-[82px] md:h-[82px] md:w-[82px] sm:h-[43px] sm:w-[43px] h-[43px] w-[43px] cursor-pointer"
              src={Logo}
              alt=""
            />
            <p className="text-[#23315E] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] font-[700]">
              Team Cheerfulness
            </p>
          </Link>
        </div>
        {isCorporatePage ? (
          <div className="space-x-[35px] w-[44%] 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden">
            <Link to="#rewards">
              <p className="text-[#23315E] text-[12px] font-[600]">REWARDS</p>
            </Link>
            <Link to="#media">
              <p className="text-[#23315E] text-[12px] font-[600]">MEDIA</p>
            </Link>
            <Link to="#sponsor">
              <p className="text-[#23315E] text-[12px] font-[600]">TIERS</p>
            </Link>
            <Link to="#branding">
              <p className="text-[#23315E] text-[12px] font-[600]">BRANDING</p>
            </Link>
            <Link to="#charity">
              <p className="text-[#23315E] text-[12px] uppercase font-[600]">
                Charities
              </p>
            </Link>
          </div>
        ) : (
          <div className="space-x-[35px]  w-[44%] 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden">
            <Link to="#challenge">
              <p className="text-[#23315E] text-[12px] font-[600]">CHALLENGE</p>
            </Link>
            <Link to="#team">
              <p className="text-[#23315E] text-[12px] font-[600]">TEAM</p>
            </Link>
            <Link to="#media">
              <p className="text-[#23315E] text-[12px] uppercase font-[600]">
                Influencer Partners
              </p>
            </Link>
            <Link to="#charity">
              <p className="text-[#23315E] text-[12px] uppercase font-[600]">
                Charities
              </p>
            </Link>
          </div>
        )}
        {isCorporatePage ? (
          <div className="space-y-[10px] flex flex-col justify-end">
            <div className="flex justify-center items-center">
              <a href="#sponsor" className="">
                <button className="bgbtn 2xl:px-[42px] 2xl:py-[15px] xl:px-[42px] xl:py-[15px] lg:px-[42px] lg:py-[15px] md:px-[42px] md:py-[15px] sm:px-[30px] sm:py-[12px] px-[30px] py-[9px]  2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[16px] text-[10px] font-[600]">
                  Get In Touch
                </button>
              </a>
            </div>
          </div>
        ) : (
          <div className="space-y-[10px] flex flex-col justify-end">
            <div className="flex justify-center items-center">
              <a href="#bundles" className="">
              <Link
                    to={!ukPath  ? "/checkout" : "/uk/checkout"}
                  >
                    <button className="bgbtn 2xl:px-[36px] 2xl:py-[10px] xl:px-[36px] xl:py-[10px] lg:px-[36px] lg:py-[10px] md:px-[36px] md:py-[10px] sm:px-[30px] sm:py-[12px] px-[30px] py-[9px]  2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[10px] font-[600]">
                      Donate
                    </button>
                  </Link>
                </a>
            </div>
          </div>
        )}
      </div>
              )}
    
    </div>
  );
}

export default Navbar;
