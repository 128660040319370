import React, { useState } from "react";

function CookieBar() {
  const [showbanner, setShowBanner] = useState(true);

  const handelClose = () => {
    setShowBanner(!showbanner);
  };
  return (
    <div>
      {showbanner && (
        <div className="fixed w-[100%] bottom-[0%] z-[99] ">
          <div className="cookiebg w-[100%]">
            <div className="flex justify-between items-center">
              <div className="2xl:py-[18px] xl:py-[18px] lg:py-[18px] md:py-[18px] sm:py-[18px] py-[15px] 2xl:w-[86%] xl:w-[86%] lg:w-[86%] md:w-[86%] sm:w-[90%] w-[95%] max-w-[1340px] mx-auto 2xl:flex xl:flex lg:flex md:flex sm:block block justify-between items-center relative">
                <p className="2xl:w-[80%] xl:w-[80%] lg:w-[75%] md:w-[80%] sm:w-[100%] w-[100%] 2xl:text-[12px] xl:text-[12px] lg:text-[12px] md:text-[12px] sm:text-[12px] text-[12px] text-start font-[500] leading-[23.28px] text-[#455174;] py-[12px] text-center font-[400]">
                  Dear visitor be advised, we use cookies to personalize your
                  browsing. By continuing to browse our site, you agree to our
                  use of cookies to enhance your experience. For more details,
                  please review our{" "}
                  <span className="underline text-[#455174] font-[400] cursor-pointer">
                    Cookie Policy.
                  </span>{" "}
                </p>
                <div className="flex justify-center">
                  <button
                    onClick={handelClose}
                    className=" mx-auto text-[10px] bg-[#1E5BB3] text-white rounded-[3px] font-[600] w-[114px] 2xl:h-[32px] xl:h-[32px] lg:h-[32px] md:h-[32px] sm:h-[32px] h-[32px] px-[32px]"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CookieBar;
