import React from "react";

import load from "../../src/assets/image/anim/anim.gif"

function Loader() {

  return (
    <div className=" h-[100vh] flex justify-center itmes-center overflow-hidden">
    <div className="flex items-center justify-center flex-col relative">
        <img className=" h-[120px]" src={load} alt="" />
          <h3 className="text-[20px] text-[#1E5BB3] absolute top-[57%] font-[500]" >Loading</h3>
    </div>
       
    </div>
  );
}

export default Loader;
